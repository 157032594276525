@use 'material-theme' as theme;

$primary: map-get(theme.$app-primary, 500);
$primary-lighter: map-get(theme.$app-primary, 300);
$primary-darker: map-get(theme.$app-primary, 700);

$primary-900: map-get(theme.$app-primary, 900);
$primary-800: map-get(theme.$app-primary, 800);
$primary-700: map-get(theme.$app-primary, 700);
$primary-600: map-get(theme.$app-primary, 600);
$primary-500: map-get(theme.$app-primary, 500);
$primary-400: map-get(theme.$app-primary, 400);
$primary-300: map-get(theme.$app-primary, 300);
$primary-200: map-get(theme.$app-primary, 200);
$primary-100: map-get(theme.$app-primary, 100);
$primary-50: map-get(theme.$app-primary, 50);

$accent: map-get(theme.$app-accent, 500);
$accent-lighter: map-get(theme.$app-accent, 300);
$accent-darker: map-get(theme.$app-accent, 700);

$accent-800: map-get(theme.$app-accent, 800);
$accent-700: map-get(theme.$app-accent, 700);
$accent-600: map-get(theme.$app-accent, 600);
$accent-500: map-get(theme.$app-accent, 500);
$accent-400: map-get(theme.$app-accent, 400);
$accent-300: map-get(theme.$app-accent, 300);
$accent-200: map-get(theme.$app-accent, 200);
$accent-100: map-get(theme.$app-accent, 100);
$accent-50: map-get(theme.$app-accent, 50);

$light: map-get(theme.$app-light, 500);
$light-lighter: map-get(theme.$app-light, 300);
$light-darker: map-get(theme.$app-light, 700);

$light-800: map-get(theme.$app-light, 800);
$light-700: map-get(theme.$app-light, 700);
$light-600: map-get(theme.$app-light, 600);
$light-500: map-get(theme.$app-light, 500);
$light-400: map-get(theme.$app-light, 400);
$light-300: map-get(theme.$app-light, 300);
$light-200: map-get(theme.$app-light, 200);
$light-100: map-get(theme.$app-light, 100);
$light-50: map-get(theme.$app-light, 50);

$grey: map-get(theme.$app-grey, default);
$grey-lighter: map-get(theme.$app-grey, lighter);
$grey-darker: map-get(theme.$app-grey, darker);

$grey-900: map-get(theme.$app-grey, 900);
$grey-800: map-get(theme.$app-grey, 800);
$grey-700: map-get(theme.$app-grey, 700);
$grey-600: map-get(theme.$app-grey, 600);
$grey-500: map-get(theme.$app-grey, 500);
$grey-400: map-get(theme.$app-grey, 400);
$grey-300: map-get(theme.$app-grey, 300);
$grey-200: map-get(theme.$app-grey, 200);
$grey-100: map-get(theme.$app-grey, 100);
$grey-50: map-get(theme.$app-grey, 50);

$white: #ffffff;
// $background: map-get(theme.$app-primary, 50);
$background: $grey-100;

$text: #212121;

$blue: #236192;
$red: #d32f2f;
$orange: #ff6f00;
