// material specific styling and overrides

// .mat-list-item-content,
// .mat-list-text,
// .mat-line {
//   width: 100% !important;
// }
@import 'variables';

textarea.mat-input-element.cdk-textarea-autosize {
   overflow: hidden;
   line-height: 20px;
}

.mat-slide-toggle.always-active {
   .mat-slide-toggle-thumb {
      background: $primary;
   }
   .mat-slide-toggle-bar {
      background: rgba($color: $primary, $alpha: 0.54);
   }
}

.mat-list-item-content {
   width: 100%;
}

.mat-progress-bar-buffer {
   background-color: $primary-100;
}

.mat-tooltip {
   font-size: 12px;
}

.mat-form-field.fill-height {
   height: 100%;
   .mat-form-field-wrapper {
      height: 100%;
      .mat-form-field-flex {
         height: 100%;
         .mat-form-field-infix {
            height: 100%;
         }
      }
   }
}

.mat-stroked-button:not([disabled]) {
   border-color: unset;
}

.mat-optgroup-label {
   margin: 0px;
   font-size: 16px;
}

.mat-autocomplete-panel {
   max-height: 400px !important;
   .search-result-category {
      label {
         height: 32px;
         color: $primary;
         line-height: 32px;
      }
   }
}